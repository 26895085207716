import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import Toast from "light-toast";
import { Helmet } from "react-helmet";
import axios from "axios";
import Loader from "../Loader";
import { axiosInstance, axiosInstanceAuth } from "../../utils/Service";

import {
  signUpRequest,
  socialLogin,
  showSignupErr,
  OTPVerify,
  loginRequest,
  resendSignOTP,
  resendEmailLink,
  OTPVerifyEmail,
  sellerRegisterationRequest,
} from "./../../store/actions/common";
import { detect } from "detect-browser";
import { SET_SIGNUP_USER_ERROR } from "./../../store/actionsTypes";
import { useToasts } from "react-toast-notifications";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import OTPForm from "./OTPform";
import { getProfileBannerInfo } from "../../store/actions/profile";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// import Select from 'react-select';
import "react-phone-number-input/style.css";
//import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import startsWith from "lodash.startswith";
import { createRef } from "react";

class Signup extends React.Component {
  constructor(props) {
    super(props);
    let isSuperAdminMode = false;
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    if (
      queryParams.has("superadmin") &&
      queryParams.get("superadmin") === "true"
    ) {
      isSuperAdminMode = true;
    }

    this.state = {
      isSuperAdminMode,
      companyName: "",
      name: "",
      lastName: "",
      password: "",
      password2: "",
      showPass1: false,
      showPass2: true,
      email: "",
      mobile: "",
      dob: "",
      checkbox: false,
      checkbox2: false,
      showInputForm: false,
      error: false,
      error2: false,
      ip: "",
      errorMsg: "",
      errorMsg2: "",
      status: "",
      refferal_code: "",
      passNotMatch: false,
      OTPForm: false,
      OTP: "",
      emailOTP: "",
      token: "", // for otp user
      time: 50, //timer for resend otp
      showCalendar: false,
      gender: "",
      showLoader: false,
      showMessage: "",
      successMessage: "",
      invalidEmail: false,
      fullName: "",
      invalidPassword: false,
      verifiedMobileOTP: false,
      verifiedEmailOTP: false,
      value: "",
      userType: "Buyer",
      dialCode: "",
      mobileApi: "",
      secondStep: false,
      firstStep: true,
      addressOne: "",
      addressOneError: false,
      state: "",
      stateError: false,
      city: "",
      cityError: false,
      zipCode: "",
      zipCodeError: false,
      PAN: "",
      panError: false,
      panValidError: false,
      cin: "",
      cinError: false,
      cinValidError: false,
      gstNumber: "",
      gstError: false,
      selectedImagePan: null,
      panImageError: false,
      selectedCINImage: null,
      selectedImageGst: null,
      gstImageError: false,
      checkbox2Error: false,
      stateList: [],
      zipCodeValidError: false,
    };
    this.companyNameRef = createRef();
    this.nameRef = createRef();
    this.lastNameRef = createRef();
    this.emailRef = createRef();
    this.passwordRef = createRef();
  }

  componentDidMount() {
    // fetch("https://api.ipify.org?format=json")
    //   .then((res) => res.json())
    //   .then((json) => {
    //     this.setState({ ip: json.ip });
    //   });
    axiosInstance.get("category/country").then((res) => {
      this.setState({ stateList: res.data.data[0].stateData });
    });
  }

  changeInput = (e) => {
    if (e.target.name === "companyName") {
      const re = /^[A-Za-z/\W|_/g ]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value, status: false });
      }
    } else if (e.target.name === "name") {
      const re = /^[A-Za-z ]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value, status: false });
      }
    } else if (e.target.name === "lastName") {
      const re = /^[A-Za-z ]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value, status: false });
      }
    } else if (e.target.name === "password") {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      let checkSpecial = specialChars.test(e.target.value);

      function hasUpperCase(str) {
        return /[A-Z]/.test(str);
      }

      function hasLowerCase(str) {
        return /[a-z]/.test(str);
      }

      // const upperCase = /^[A-Z]*$/;
      // let checkUpperCase = upperCase.test(e.target.value);

      const number = /\d/;
      let checkNumber = number.test(e.target.value);

      if (
        checkSpecial &&
        hasUpperCase(e.target.value) &&
        hasLowerCase(e.target.value) &&
        checkNumber &&
        e.target.value.length >= 8
      ) {
        this.setState({
          [e.target.name]: e.target.value,
          invalidPassword: false,
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          invalidPassword: true,
        });
      }
    } else this.setState({ [e.target.name]: e.target.value, status: false });
  };

  changeInputEmail = (e) => {
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (filter.test(e.target.value)) {
      this.setState(
        { [e.target.name]: e.target.value, status: false, invalidEmail: false },
        () =>
          this.props.showSignupErr({
            type: SET_SIGNUP_USER_ERROR,
            payload: { errData: "", status: false },
          })
      );
      this.props.showSignupErr({
        type: SET_SIGNUP_USER_ERROR,
        payload: { errData: "", status: false },
      });
    } else {
      if (e.target.value.length > 0) {
        this.setState({ [e.target.name]: e.target.value, invalidEmail: true });
      } else {
        this.setState({ [e.target.name]: e.target.value, invalidEmail: false });
      }
    }
  };

  handleEnter = (event) => {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  resendEmail = () => {
    this.props.resendEmailLink({ token: this.state.token }, (callback) => {
      this.props.addToast(callback.message, { appearance: "success" });
      this.setState({ time: 50 });
    });
  };

  loginFunction = () => {
    this.setState({ showLoader: true, showMessage: "Please wait..." });
    const payload = {
      email: this.state.email,
      password: this.state.password,
    };
    localStorage.setItem("rememberMe", true);
    localStorage.setItem("bazaarEmail", payload.email);
    localStorage.setItem("bazaarPass", payload.password);

    this.props.loginRequest(payload, (status) => {
      if (status.status) {
        this.props.getProfileBannerInfo();
        window.location.reload();
        this.setState({ showLoader: false });
        this.props.addToast("Login Successfully", { appearance: "success" });
        this.props.closePopup(false);
        this.props.addToast(status.data.errData, { appearance: "error" });
      }
    });
  };

  ChangeFormateDate = () => {
    const [dd, mm, yy] = this.state.dob.split(/-/g);
    return `${mm}-${dd}-${yy}`;
  };
  setDateOfBirth = (props) => {
    const newDOB =
      props.getDate() +
      "-" +
      (props.getMonth() + 1) +
      "-" +
      props.getFullYear();
    this.setState({ showCalendar: !this.state.showCalendar, dob: newDOB });
  };

  backHandler = () => {
    this.setState({ OTPForm: false });
  };

  // panImageHandler = (e) => {
  //   this.setState({ selectedImagePan: e.target.files[0] });
  //   this.setState({ panImageError: false });
  // };
  // cinImageHandler = (e) => {
  //   this.setState({ selectedCINImage: e.target.files[0] });
  // };
  // gstImageHandler = (e) => {
  //   this.setState({ selectedImageGst: e.target.files[0] });
  //   this.setState({ gstImageError: false });
  // };

  formSubmit = (e) => {
    e.preventDefault();
    if (this.state.invalidEmail) {
      return null;
    } else {
      this.setState({ passNotMatch: false });
      this.setState({ showLoader: true, showMessage: "Please wait..." });
      const browser = detect();
      const payload = {
        name: this.state.name,
        email: this.state.email,
        companyName: this.state.companyName,
        mobile: this.state.mobileApi,
      };

      let formdata = new FormData();
      for (let item in payload) {
        formdata.append(item, payload[item]);
      }

      this.props.sellerRegisterationRequest(formdata, (status) => {
        if (status.res.status === "success") {
          this.setState({ showLoader: false });
          this.props.closePopup(true);
          Toast.success(
            "Thanks to connecting with us, metalsbuy team will connect you shortly. ",
            3000
          );
          // this.setState({ successMessage: "Thanks to connecting with us, metalsbuy team will connect you shortly." });
          // this.props.addToast(status.res.message ? status.res.message : "OTP sent on your mobile and email", { appearance: "success" });
        } else {
          this.setState({
            errorMsg: status.res.Message,
            status: status.res.status,
          });
          this.setState({ showLoader: false });
          this.props.addToast(status.res.Message, { appearance: "error" });
          // Toast.fail("SignUp Fail..", 1000);
        }
      });
    }
  };

  back = () => {
    this.setState({ firstStep: true, secondStep: false });
  };

  render() {
    const { email } = this.state;

    return (
      <>
        {this.state.showLoader ? (
          <Loader message={this.state.showMessage} />
        ) : null}
        <div
          className="outside-layer"
          onClick={() => this.props.closePopup(true)}
        ></div>
        <div
          className={
            "signup-form signup-frm-wrp signup-page-wrapp signup-new-wrap"
          }
        >
          <Helmet>
            <style>
              {`
           .facebook-button-holder, .google-button-holder {
            position: relative;
           }
            .facebook-button-holder span button, .google-button-holder button:first-child {
              position: absolute;
              left: 0;
              top: 0;
              z-index: 15;
              width: 100%;
              height: 100%;
             opacity:0 !important;
            }
            `}
            </style>
          </Helmet>

          <button
            className="close-btn"
            // onClick={() => this.props.closePopupAll()}
            onClick={() => this.props.closePopup(true)}
          >
            <i className="fal fa-times-circle"></i>
          </button>

          <div className="inner-sign signup-without-img">
            <div className="sign-form">
              <form autoComplete="off" onSubmit={(e) => this.formSubmit(e)}>
                <div className="detail-from signup-detail-form sign-up-form-wrap">
                  <div className="user-detail-edit signup-head-wrap">
                    <div className="logo-wrapper">
                      <Link to="/" className="logo-wrap">
                        <img
                          src="/assets/images/metal-buy-logoo.png"
                          alt="logo"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="form-block-wrap">
                    <div className="enter-mobile-label">Company Name*</div>
                    <div className="form-input">
                      <TextField
                        variant="outlined"
                        type="text"
                        name="companyName"
                        // label="Company Name*"
                        placeholder="Enter company name here"
                        value={this.state.companyName}
                        onChange={(e) => this.changeInput(e)}
                        onKeyDown={this.handleEnter}
                        required
                        InputProps={{
                          inputProps: {
                            ref: this.companyNameRef,
                            maxLength: 30,
                            minLength: 2,
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="names-wrapper">
                    <div className="form-block-wrap">
                      <div className="enter-mobile-label">Name*</div>
                      <div className="form-input">
                        <TextField
                          required
                          variant="outlined"
                          type="text"
                          name="name"
                          // label="Full Name*"
                          placeholder="Enter Name"
                          value={this.state.name}
                          onChange={(e) => this.changeInput(e)}
                          onKeyDown={this.handleEnter}
                          InputProps={{
                            inputProps: {
                              ref: this.nameRef,
                              maxLength: 30,
                              minLength: 2,
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-block-wrap">
                    <div className="enter-mobile-label">Email*</div>
                    <div className="form-input">
                      <TextField
                        variant="outlined"
                        required
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                        // label="Email Address or Mobile No.*"
                        value={email}
                        onChange={(e) => this.changeInputEmail(e)}
                        onKeyDown={this.handleEnter}
                        inputProps={{
                          ref: this.emailRef,
                        }}
                      />
                      {this.state.invalidEmail ? (
                        <span className="error">
                          Enter a valid email address
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="enter-mobile-label">Mobile*</div>
                  <div className="form-input number-code-wrapper signup-phone-input signup-code">
                    <PhoneInput
                      country={"in"}
                      disableDropdown={true}
                      placeholder="Enter mobile no."
                      className={this.state.mobile && "arrow-hide"}
                      enableSearch={true}
                      value={this.state.mobile}
                      onChange={(phone, country) => {
                        const reducedPhone = phone.replace(
                          country.dialCode,
                          ""
                        );
                        this.setState({
                          mobile: phone,
                          mobileApi: reducedPhone,
                          dialCode: country.dialCode,
                        });
                      }}
                      isValid={(value, country) => {
                        if (value.length === 0) {
                          return false;
                        } else {
                          return true;
                        }
                      }}
                      inputProps={{
                        required: true,
                      }}
                    />

                    <div className="login-btn-wrapper">
                      <button type={"submit"} className="shop-now">
                        Submit
                      </button>
                      {/* <button className="shop-now" onClick={()=>{this.demo()}}>DEmo </button> */}
                    </div>
                    {/* <div className="login-condition">
                    <p>Don’t have an account? <button className="sign-up-here">Sign up here</button></p>
                  </div> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    signUpRequest: (payload, completion) =>
      dispatch(signUpRequest(payload, completion)),
    sellerRegisterationRequest: (payload, completion) =>
      dispatch(sellerRegisterationRequest(payload, completion)),
    // getToken:(payload, completion) =>
    //   dispatch(getToken(payload, completion)),
    OTPVerify: (payload, completion) =>
      dispatch(OTPVerify(payload, completion)),
    OTPVerifyEmail: (payload, completion) =>
      dispatch(OTPVerifyEmail(payload, completion)),
    resendSignOTP: (payload, compilation) =>
      resendSignOTP(payload, compilation),
    resendEmailLink: (payload, compilation) =>
      resendEmailLink(payload, compilation),
    loginRequest: (payload, completion) =>
      dispatch(loginRequest(payload, completion)),
    socialLogin: (payload, completion) =>
      dispatch(socialLogin(payload, completion)),
    showSignupErr: (payload) => dispatch(showSignupErr(payload)),
    getProfileBannerInfo: () => dispatch(getProfileBannerInfo()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  showLoginPopup: state.generalInfo.showLoginPopup,
  status: state.userInfo.SignupError.status,
  errData: state.userInfo.SignupError.errData,
});
export default withRouter(
  withToast(connect(mapStateToProps, mapDispatchToProps)(Signup))
);

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
}
