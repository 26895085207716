import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import ProductReview, { RatingStar } from "../../ProductDetail/productReview";
import { apiUrl } from "../../../utils/urlEndpoints";
import { axiosInstance } from "../../../utils/Service";
import { useDispatch } from "react-redux";
import Loader from "../../Loader";
import { useParams } from "react-router-dom";

const SellerDetails = () => {
  const [userData, setUserData] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  let { id } = useParams();
  var settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    const fetchData = async () => {
      const Payload = {
        user_Id: id,
      };
      try {
        setShowLoader(true);
        await axiosInstance
          .post(apiUrl.profile.userProfile, Payload)
          .then((res) => {
            console.log(res);
            if (res.data.status === "success") {
              setShowLoader(false);
            }
            setUserData(res.data.data);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
        setShowLoader(false);
      }
    };

    fetchData();
  }, []);
  console.log(userData);
  return (
    <>
      <div className="seller-details-wrp">
        {showLoader ? <Loader message={"...loading"} /> : null}
        <div className="container">
          <div className="seller-info">
            {userData &&
                  userData.userData[0] &&
                  userData.userData[0].user_image && <div className="seller-prof-img">
              <img
                src={userData.userData[0].user_image}
                alt="user-prof"
              />
            </div>}
            <div className="seller-title-wrap">
              <h4>
                {userData &&
                  userData.userData[0] &&
                  userData.userData[0].companyName}
              </h4>
              <div className="rating-review-wrap ratings-wrapper seller-review">
                {userData && userData.average_rating ? (
                  <RatingStar value={userData.average_rating} />
                ):null}
                <div className="review-count">
                  <span className="review-number">
                    {userData &&
                      userData.review_Data &&
                      userData.review_Data.length}
                  </span>{" "}
                  Reviews
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="user-data-info">
        <div className="container">
          <div className="info-wrp">
            {/* <div className="form-wrap company-head">
              <label className="enter-mobile-label">Company Name:</label>
              <span className="label-value">
                {userData && userData.userData && userData.userData.companyName}
              </span>
            </div> */}
            <div className="address-wrap">
              <h3>Mailing Address:</h3>
              <div className="input-col-wrap">
                <div className="form-wrap">
                  <label className="enter-mobile-label">Address line:</label>
                  <span className="label-value">
                    {userData &&
                      userData.addressData &&
                      userData.addressData.billing_flat_no}
                  </span>
                </div>
                <div className="form-wrap">
                  <label className="enter-mobile-label">City:</label>
                  <span className="label-value">
                    {userData &&
                      userData.addressData &&
                      userData.addressData.billing_city}
                  </span>
                </div>
                <div className="form-wrap">
                  <label className="enter-mobile-label">State:</label>
                  <span className="label-value">
                    {userData && userData.stateData && userData.stateData.name}
                  </span>
                </div>
              </div>
            </div>
            <div className="address-wrap">
              <div className="input-col-wrap">
                <div className="form-wrap">
                  <label className="enter-mobile-label">GST number:</label>
                  <span className="label-value">
                    {userData &&
                      userData.userData &&
                      userData.userData[0] &&
                      userData.userData[0].gst}
                  </span>
                </div>
                { userData &&
                      userData.userData &&
                      userData.userData[0] &&
                      userData.userData[0].businessYears && <div className="form-wrap">
                  <label className="enter-mobile-label">
                    Business years:
                  </label>
                  <span className="label-value">{userData &&
                      userData.userData &&
                      userData.userData[0] &&
                      userData.userData[0].businessYears}</span>
                </div>}
                {userData &&
                      userData.userData &&
                      userData.userData[0] &&
                      userData.userData[0].monthlyNeed && <div className="form-wrap">
                  <label className="enter-mobile-label">
                    Aggregate Monthly Need (In Mt):
                  </label>
                  <span className="label-value">{userData &&
                      userData.userData &&
                      userData.userData[0] &&
                      userData.userData[0].monthlyNeed}</span>
                </div>}
                <div className="form-wrap">
                  <label className="enter-mobile-label">Designation*:</label>
                  <span className="label-value">
                    {userData &&
                      userData.userData &&
                      userData.userData[0] &&
                      userData.userData[0].designation}
                  </span>
                </div>
              </div>
            </div>
            <div className="address-wrap manufactring-wrap">
              <h3>Manufacturing Plant Address:</h3>
              <div className="input-col-wrap">
                <div className="form-wrap">
                  <label className="enter-mobile-label">City:</label>
                  <span className="label-value">
                    {" "}
                    {userData &&
                      userData.addressData &&
                      userData.addressData.city}
                  </span>
                </div>
                <div className="form-wrap">
                  <label className="enter-mobile-label">State:</label>
                  <span className="label-value">
                    {" "}
                    {userData &&
                      userData.m_stateData &&
                      userData.m_stateData.name}
                  </span>
                </div>
                <div className="form-wrap">
                  <label className="enter-mobile-label">Address Line 1:</label>
                  <span className="label-value">
                    {userData &&
                      userData.addressData &&
                      userData.addressData.flat_no}
                  </span>
                </div>
                {/* <div className="form-wrap">
                  <label className="enter-mobile-label">Address Line 2:</label>
                  <span className="label-value">
                    staticBuyer Address line 123
                  </span>
                </div> */}
                <div className="form-wrap">
                  <label className="enter-mobile-label">
                    Interested In Buying{" "}
                  </label>
                  {userData &&
                    userData.userData &&
                    userData.userData[0] &&
                    userData.userData[0].interstInBuying &&
                    userData.userData[0].interstInBuying.map((item, index) => (
                      <span className="label-value">
                        {item}
                        {userData.userData[0].interstInBuying.length - 1 !==
                          index && ","}
                      </span>
                    ))}
                </div>
                <div className="form-wrap">
                  <label className="enter-mobile-label">
                    Affiliations & Assocations::
                  </label>
                  {userData &&
                    userData.userData &&
                    userData.userData[0] &&
                    userData.userData[0].affiliationData.map((item) => (
                      <span className="label-value">{item.name}</span>
                    ))}
                </div>
                <div className="form-wrap">
                  <label className="enter-mobile-label">
                    Product Manufacturing:
                  </label>
                  {userData &&
                    userData.userData &&
                    userData.userData[0] &&
                    userData.userData[0].productManufacturing.map((item) => (
                      <span className="label-value">{item}</span>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {userData &&
              userData.review_Data && userData.review_Data.length>0 && <div className="seller-review-wrapper">
        <div className="container">
          <h3>Reviews</h3>
          <Slider {...settings}>
            {userData &&
              userData.review_Data &&
              userData.review_Data.map((item) => {
                return (
                  <div className="review-card-wrap" key={item.id} style={{height:"70px"}}>
                    <div className="review-card">
                      <div className="prof-img">
                        <span className="product-review-images">
                          <img
                            src={item.buyerData.user_image}
                            alt="user-prof"
                            style={{objectFit:"cover"}}
                          />
                        </span>
                        <br />
                        <span className="name">{item.buyerData.full_name}</span>
                      </div>
                      <div className="review-content">{item.comment}</div>

                      <div className="rating-review-wrap ratings-wrapper seller-review">
                        <RatingStar value={item.rating} />
                      </div>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>}
    </>
  );
};

export default SellerDetails;
