import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { axiosInstance } from "../../../utils/Service";
import moment from "moment";
import { useSelector } from "react-redux";
import DeliveryAddress from "../../OrderDetails/deliveryAddress";
import CounterRfq from "../../ProductDetail/CounterRfq";
import Loader from "../../Loader";
import Countdown from "react-countdown";

const RfqDetail = () => {
  const userData = useSelector(
    (store) => store.userProfile.userProfileBanner.userdata
  );
  const [loader, setLoader] = useState(false);
  const [productsDetail, setProductDetail] = useState(null);
  const [offerPopUp, setOfferPopup] = useState(false);
  const [sellerData, setSellerData] = useState([]);
  const { prodId } = useParams();
  useEffect(() => {
    handleRfqDetail();
  }, [prodId, userData]);

  const handleRfqDetail = () => {
    let token = localStorage.getItem("token");
    setLoader(true);
    if (userData && userData.userType) {
      axiosInstance
        .post(
          `/requestForQuote/request_details`,
          {
            userType: userData.userType,
            requestId: prodId,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setProductDetail(res.data);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const handleSellerAccept = () => {
    console.log("first", productsDetail);
    let token = localStorage.getItem("token");
    setLoader(true);
    if (userData && userData.userType) {
      axiosInstance
        .post(
          `/requestForQuote/seller_accept_rquest`,
          {
            requestId: productsDetail.data._id,
            // seller_request_id: productsDetail.data.sellerData.seller_request_id,
            sellerId: userData._id,
            buyerId: productsDetail.data.buyerId,
            isAccepted: true,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setLoader(false);
          window.location.reload();
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const handleBuyerAccept = (item) => {
    let token = localStorage.getItem("token");
    setLoader(true);
    if (userData && userData.userType) {
      axiosInstance
        .post(
          `/requestForQuote/buyer_accept`,
          {
            requestId: productsDetail.data._id,
            seller_request_id: item.seller_request_id,
            seller_Id: item.sellerId,

            quantity:
              item.counterOffer && item.counterOffer.length
                ? item.counterOffer[0].quantity
                : productsDetail.data.quantity,
            price:
              item.counterOffer && item.counterOffer.length
                ? item.counterOffer[0].price
                : productsDetail.data.price,
            delivery_window_from: productsDetail.data.delivery_window_from,
            delivery_window_to: productsDetail.data.delivery_window_to,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setLoader(false);
          window.location = "/profile/myorder/processed";
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  console.log("productsDetail", productsDetail);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return null;
    } else {
      // Render a countdown
      return (
        <div className="count-wrap" style={{ justifyContent: "flex-start" }}>
          <p>Expires in:</p>
          <span style={{ color: "#ed9829" }}>
            <span>
              {minutes}:{seconds}
            </span>
            &nbsp; mins
          </span>
        </div>
      );
    }
  };

  return (
    <div className="order-details order-detail-page">
      {loader ? <Loader message={"Please wait..."} /> : null}
      {productsDetail && productsDetail.data ? (
        <div className="multiple-items multiple-items-wrapper new-multiple-wrap">
          <div className="container">
            <div className="ordered-products order-product-wrp">
              <div className="order-left order-delivery-location">
                <div className="order-left-top">
                  <span className="order-title">Request for Quotation</span>
                </div>
              </div>
              <div className="offer-column">
                {/* <h5>Your offer</h5> */}
                <div
                  className="products-images products-images-wrapper pending-order"
                  style={{ justifyContent: "flex-start" }}
                >
                  <div className="">
                    <div className="cart-item-detail">
                      <div className="value">
                        <div className="current-value">Name:</div>
                        <div className="new-value">
                          {productsDetail.data.productName}
                        </div>
                      </div>
                      <div className="value">
                        <div className="current-value">Product type:</div>
                        <div className="new-value">
                          {productsDetail.data.type}
                        </div>
                      </div>
                      <div className="value">
                        <div className="current-value">Date and time:</div>
                        <div className="new-value">
                          {moment(productsDetail.data.createdAt).calendar({
                            sameDay: "DD/MM/YYYY h:mm a",
                            nextDay: "DD/MM/YYYY h:mm a",
                            nextWeek: "DD/MM/YYYY h:mm a",
                            lastDay: "DD/MM/YYYY h:mm a",
                            lastWeek: "DD/MM/YYYY h:mm a",
                            sameElse: "DD/MM/YYYY h:mm a",
                          })}
                        </div>
                      </div>
                      <div className="value">
                        <div className="current-value">Quantity:</div>
                        <div className="new-value">
                          {productsDetail.data.quantity}
                        </div>
                      </div>
                      {productsDetail.data.price ? (
                        <div className="value">
                          <div className="current-value">Offer price:</div>
                          <div className="new-value">
                            ₹{productsDetail.data.price}/mt
                          </div>
                        </div>
                      ) : null}

                      <div className="value">
                        <div className="current-value">Size:</div>
                        <div className="new-value">
                          {productsDetail.data.size}
                        </div>
                      </div>
                      <div className="value">
                        <div className="current-value">Grade:</div>
                        <div className="new-value">
                          {productsDetail.data.grade}
                        </div>
                      </div>
                      <div className="value">
                        <div className="current-value">Payment terms:</div>
                        <div className="new-value">
                          {productsDetail.data.paymentTerms}
                        </div>
                      </div>
                      <div className="value">
                        <div className="current-value">Packaging:</div>
                        {productsDetail.data.packaging.map((ele, index) => {
                          return (
                            <div className="new-value" key={index}>
                              {ele}
                            </div>
                          );
                        })}
                      </div>
                      <div className="value">
                        <div className="current-value">Delivery:</div>
                        <div className="new-value">
                          {productsDetail.data.delivery}
                        </div>
                      </div>
                      <div className="value">
                        <div className="current-value">Delivery Window:</div>
                        <div className="new-value">
                          {moment(
                            productsDetail.data.delivery_window_from
                          ).format("Do MMM")}{" "}
                          -{" "}
                          {moment(
                            productsDetail.data.delivery_window_to
                          ).format("Do MMM")}
                        </div>
                      </div>
                    </div>
                    {productsDetail.data.commentBox ? (
                      <div className="value">
                        <div className="current-value">Additional Comment:</div>
                        <div className="new-value">
                          {productsDetail.data.commentBox}
                        </div>
                      </div>
                    ) : null}

                  </div>

                  <div
                    className="action-value value-tab"
                    style={{
                      width: "100%",
                      maxWidth: "220px",
                      marginLeft: "auto",
                    }}
                  >
                    {userData && userData.userType === "Buyer" ? (
                      <Countdown
                        date={
                          new Date(productsDetail.data.updatedAt).getTime() +
                          60 * 60 * 1000
                        }
                        renderer={renderer}
                        zeroPadTime={2}
                      />
                    ) : (
                      <Countdown
                        date={
                          new Date(productsDetail.data.updatedAt).getTime() +
                          30 * 60 * 1000
                        }
                        renderer={renderer}
                        zeroPadTime={2}
                      />
                    )}
                  </div>
                </div>
                {userData  && productsDetail &&
                  productsDetail.data &&
                  productsDetail.data.sellerData ? (
                  <div className="value" style={{ marginTop: "10px" }}>
                    <div className="current-value">Sellers received Request For Quote:</div>
                    <div className="flex-list">
                    {
                      productsDetail.data.sellerData.map((item, index) => {
                        return (
                          <div>
                            <span key={item.sellerId}>
                              {item.companyName}
                              {/* {(item && item.is_primary  ? <h5>(preferred seller)</h5> : " ")} */}
                            </span>
                            {
                              userData.userType === "Seller" &&
                            <button
                              className="date-wrp make-new-payment-btn"
                              onClick={() => {
                                setOfferPopup(true);
                                setSellerData(item);
                              }}
                            >
                              Counter
                            </button>
                            }
                          </div>
                        );
                      })
                    }
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {userData &&
              userData.userType === "Seller" &&
              productsDetail &&
              productsDetail.data &&
              productsDetail.data.sellerData &&
             !productsDetail.data.isAccepted ? (
              <div className="rfq-actions">
                {/* <button
                  className="date-wrp make-new-payment-btn pay-btn"
                  onClick={() => setOfferPopup(true)}
                >
                  Counter
                </button> */}
                {productsDetail.data && productsDetail.data.price ? (
                  <button
                    className="date-wrp make-new-payment-btn pay-btn"
                    onClick={handleSellerAccept}
                  >
                    Accept
                  </button>
                ) : null}
              </div>
            ) : null}
            {userData &&
              userData.userType === "Buyer" &&
              productsDetail &&
              productsDetail.data &&
              (productsDetail.data.isCounter || productsDetail.data.isAccepted) ? (
              <div className="ordered-products order-product-wrp">
                <div className="order-left order-delivery-location">
                  <div className="order-left-top">
                    <span className="order-title">Offers received</span>
                  </div>
                </div>
                <div className="offer-column">
                  <div className="offers-got">
                    <ul className="offers-rfq-listing">
                      <li>
                        <div className="rfq-offers-details">
                          <div className="rfq-detail naming">
                            <h5>Seller Company Name</h5>
                          </div>
                          <div className="rfq-detail">
                            <h5>Quantity</h5>
                          </div>
                          <div className="rfq-detail">
                            <h5>Price</h5>
                          </div>
                          <div className="rfq-detail">
                            <h5 style={{ width: "86px" }}>Action</h5>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <ul className="offers-rfq-listing">
                      {productsDetail &&
                        productsDetail.data &&
                        productsDetail.data.sellerData ? (
                        productsDetail.data.sellerData.map((item) => {
                          return item.isCounter || item.isSellerAccepted ? (
                            <li key={item.sellerId}>
                              <div className="rfq-offers-details">
                                <div className="rfq-detail naming">
                                  <Link
                                    to={`/user-profile/${item.sellerId}`}
                                    className="user-prof-link"
                                  >
                                    <h6>{item.companyName}</h6>
                                  </Link>
                                </div>
                                <div className="rfq-detail">
                                  {item.isCounter ? (
                                    <h6>{item.counterOffer[0].quantity}</h6>
                                  ) : (
                                    <h6>{productsDetail.data.quantity}</h6>
                                  )}
                                </div>
                                <div className="rfq-detail">
                                  {item.isCounter ? (
                                    <h6>₹{item.counterOffer[0].price}</h6>
                                  ) : (
                                    <h6>₹{productsDetail.data.price}</h6>
                                  )}
                                </div>
                                <div className="rfq-detail">
                                  <button
                                    onClick={() => handleBuyerAccept(item)}
                                    className="date-wrp make-new-payment-btn pay-btn"
                                  >
                                    Accept
                                  </button>
                                </div>
                              </div>
                              {item.counterOffer &&
                                item.counterOffer[0] &&
                                item.counterOffer[0].comments ? (
                                <div className="rfq-comment">
                                  <h5>Additional comment:</h5>
                                  <p>{item.counterOffer[0].comments}</p>
                                </div>
                              ) : null}
                            </li>
                          ) : null
                        })
                      ) : (
                        <h5>No offer received</h5>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            ) : productsDetail &&
              productsDetail.data &&
              productsDetail.data.sellerData &&
              productsDetail.data.sellerData.counterOffer &&
              productsDetail.data.sellerData.counterOffer.length > 0 ? (
              <div className="ordered-products order-product-wrp">
                <div className="order-left order-delivery-location">
                  <div className="order-left-top">
                    <span className="order-title">Your offer</span>
                  </div>
                </div>
                <div className="offer-column">
                  {/* <h5>Your offer</h5> */}
                  <div className="offers-got">
                    <ul className="offers-rfq-listing">
                      <li>
                        <div className="rfq-offers-details">
                          <div className="rfq-detail naming">
                            <h5>Seller Name</h5>

                            <Link
                              to={`/user-profile/${productsDetail.data.sellerData._id}`}
                              className="user-prof-link"
                            >
                              <h6>
                                {productsDetail.data.sellerData.companyName}
                              </h6>
                            </Link>
                          </div>
                          <div className="rfq-detail">
                            <h5>Quantity</h5>
                            <h6>
                              {
                                productsDetail.data.sellerData.counterOffer[0]
                                  .quantity
                              }
                            </h6>
                          </div>
                          <div className="rfq-detail">
                            <h5>Price</h5>
                            {productsDetail.data.sellerData.counterOffer &&
                              productsDetail.data.sellerData.counterOffer[0] &&
                              productsDetail.data.sellerData.counterOffer[0]
                                .price ? (
                              <h6>
                                ₹
                                {
                                  productsDetail.data.sellerData.counterOffer[0]
                                    .price
                                }
                              </h6>
                            ) : null}
                          </div>
                          {/* <div className='rfq-detail'>
                                            <button className='date-wrp make-new-payment-btn pay-btn'>Accept</button>
                                        </div> */}
                        </div>
                        <div className="rfq-comment">
                          <h5>Additional comment:</h5>
                          <p>
                            {productsDetail.data.sellerData.counterOffer &&
                              productsDetail.data.sellerData.counterOffer[0] &&
                              productsDetail.data.sellerData.counterOffer[0]
                                .comments}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}

            {userData &&
              userData.userType === "Buyer" &&
              !(
                productsDetail &&
                productsDetail.data &&
                productsDetail.data.isCounter ||
                productsDetail.data.isAccepted

              ) ? (
              <div className="ordered-products order-product-wrp">
                <div className="order-left order-delivery-location">
                  <div className="order-left-top">
                    <span className="order-title">Offers received</span>
                  </div>
                </div>
                <h5 style={{ textTransform: "none" }}>No offer received</h5>
              </div>
            ) : null}
            <div
              className="ordered-products order-product-wrp"
              style={{ marginTop: "20px", borderRadius: "10px" }}
            >
              <DeliveryAddress
                userData={userData}
                orderdetail={{
                  product_detail: {
                    user_address_details: productsDetail.data.address_data[0],
                    offerId: productsDetail.data._id,
                    createdDate: productsDetail.data.createdAt,
                    // seller_details: detailData.data[0].seller_data[0],
                    // buyer_details: detailData.data[0].buyerData,
                    // offerStatus: detailData.data[0].offerStatus
                  },
                  customer_name: productsDetail.data.address_data[0].full_name
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
      {offerPopUp && (
        <CounterRfq
          setLoader={setLoader}
          setOfferPopup={setOfferPopup}
          productsDetail={productsDetail}
          sellerData={sellerData}
        />
      )}
    </div>
  );
};

export default RfqDetail;
