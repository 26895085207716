import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import { axiosInstance } from '../../../../utils/Service';
import { ButtonLoader } from '../../../../utils/constants';
import Countdown from 'react-countdown';




const RfqProduct = ({ name, setLoaderState, setShowLoader }) => {
    let history = useHistory();
    let location = useLocation()
    const [offerDataListRfq, setofferDataListRfq] = useState([])
    const [totalOffers, setTotalOffers] = useState(null)
    const [page, setPage] = useState(1)
    const [moreLoading, setMoreLoading] = useState(false)
    const userData = useSelector(store => store.userProfile.userProfileBanner.userdata);

    console.log("first", location)

    useEffect(() => {
        if (userData) {
            let token = localStorage.getItem("token");
            setShowLoader(true)
            axiosInstance.post(`/requestForQuote/request_list`, {
                "userType": userData.userType,
                "requestStatus": name === "Active" ? "Pending" : name
            }, {
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
            }).then((res) => {
                setofferDataListRfq(res.data.data)
                // setTotalOffers(res.data.totalLength)
                setMoreLoading(false)
                setLoaderState()
            }).catch(() => {
                setMoreLoading(false)
                setLoaderState()
            });

        }
    }, [userData, name])

    const handleLoadMore = () => {
        setPage(page + 1);
        setMoreLoading(true)
        if (userData) {
            if (userData.userType === "Buyer") {
                let token = localStorage.getItem("token");
                axiosInstance.post(`/offers/buyer_offer_list`, {
                    offerStatus: name,
                    page: page + 1
                }, {
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "application/json"
                    },
                }).then((res) => {
                    setofferDataListRfq([...offerDataListRfq, ...res.data.data])
                    setTotalOffers(res.data.totalLength)
                    setMoreLoading(false)
                }).catch(() => {
                    setMoreLoading(false)
                });
            }
            if (userData.userType === "Seller") {
                let token = localStorage.getItem("token");
                axiosInstance.post(`/offers/seller_offer_list`, {
                    offerStatus: name,
                    page: page + 1
                }, {
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "application/json"
                    },
                }).then((res) => {
                    setofferDataListRfq([...offerDataListRfq, ...res.data.data])
                    setTotalOffers(res.data.totalLength)
                    setMoreLoading(false)
                }).catch(() => {
                    setMoreLoading(false)
                });
            }
        }
    }

    const handleLink = (event) => {
        console.log("event", event)
        history.push({ pathname: `/profile/rfq-offers/offer-detail/${event.id}/${event.name}`, state: { ...location.state, event } });
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return null;
        } else {
            // Render a countdown
            return (
                <div className="count-wrap" style={{ justifyContent: "flex-start" }}>
                    <p>Expires in:</p>
                    <span style={{ color: "#ed9829" }}>
                        <span>
                            {minutes}:{seconds}
                        </span>
                        &nbsp; mins
                    </span>
                </div>
            );
        }
    };

    return (
        <div className="plp-product-screen">
            {offerDataListRfq && offerDataListRfq.length ?
                offerDataListRfq.map((item, index) => {
                    return <div key={index} className="buy-prod buy-product-wrapper order-process-wrapper">
                        <div className="inner-product-detail-wrap new-list-wrapper">
                            {/* <div className="est-deliveries order-east-deliveries">
                                <div className="prod-img order-product-img" style={{ "width": "148px", height: "148px" }}><button style={{ "width": "148px", height: "148px" }}><img
                                    src={item.product_data[0].default_image}
                                    onClick={() => handleLink({ id: item._id, name })}
                                    alt="img" /></button></div>
                            </div> */}
                            <div className="prod-detail product-orderinfo product-info-detail-wrap" style={{ alignItems: "flex-start" }}>
                                <Link onClick={() => handleLink({ id: item._id, name })} className="prod-link prod-new-link"
                                >
                                    <div className="product-box-wrapper sub-detail-order">
                                        <div className="home-detail qty-pricing">
                                            <div className="home-details"><span className="prod-text bold sub-name">Name: {item.productName}
                                            </span></div>
                                            <div className="home-details"><span className="prod-text bold sub-name">Quantity:&nbsp;</span><span
                                                className="prod-text value">{item.quantity}mt</span></div>
                                            {item.price ? <div className="home-details"><span className="prod-text bold sub-name">Price:&nbsp;</span><span
                                                className="prod-text value">₹{item.price}</span></div> : null}
                                            <div className="home-details"><span className="prod-text bold sub-name">Payment Terms:&nbsp;</span><span
                                                className="prod-text value">{item.paymentTerms}</span></div>
                                            {/* {!(userData.userType === "Seller" && name === "AutoDeclined") && <div className="home-detail qty-pricing">
                                                <div className="home-details"><span className="prod-text bold sub-name">Company name:&nbsp;</span><span
                                                    className="prod-text value">{
                                                        (item.seller_data &&
                                                            item.seller_data[0] &&
                                                            item.seller_data[0].companyName) ?
                                                            item.seller_data[0].companyName :
                                                            (item.buyerData &&
                                                                item.buyerData.companyName) ?
                                                                item.buyerData.companyName : null
                                                    }</span></div>
                                            </div>} */}
                                        </div>
                                    </div>
                                </Link>
                                <div className=''>
                                    <button style={{ width: "222px" }} onClick={() => handleLink({ id: item._id, name })} className='date-wrp make-new-payment-btn pay-btn'>View offer details</button>
                                    {/* <div className="order-status-wrapper-box" style={{ marginBottom: "8px" }}>
                                        <div className="count-wrap" style={{ justifyContent: "flex-start" }}>
                                            <p>Offer status:</p>
                                            <div className="order-acceptance date-wrp">
                                                <span className="process order-request-state date-wrp">
                                                    {item.offerStatus === "AutoDeclined" ? "Auto Declined" : 
                                                    item.offerStatus === "CounterOffer" ? "Counter Offer" : item.offerStatus}</span>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* {location.pathname === "/profile/my-offer/pending" ? !(item.offerData.length === 1 && userData.userType === "Seller") && <div className="order-status-wrapper-box" style={{ marginBottom: "8px" }}>
                                        <div className="count-wrap" style={{ justifyContent: "flex-start" }}>
                                            <p>Offer seen:</p>
                                            <div className="order-acceptance date-wrp">
                                                <span className="">{item.readStatus ? "Yes" : "No"}</span>
                                            </div>
                                        </div>
                                    </div> : null} */}
                                    {location.pathname === "/profile/rfq-offers/active" && <div className="order-status-wrapper-box">
                                        <div className="order-acceptance date-wrp">
                                            <span className="">
                                                {userData && userData.userType === "Buyer" ? <Countdown
                                                    date={new Date(item.updatedAt).getTime() + 60 * 60 * 1000}
                                                    renderer={renderer}
                                                    zeroPadTime={2}
                                                /> :
                                                    <Countdown
                                                        date={new Date(item.updatedAt).getTime() + 30 * 60 * 1000}
                                                        renderer={renderer}
                                                        zeroPadTime={2}
                                                    />}
                                            </span>
                                        </div>
                                    </div>}
                                </div>
                            </div>

                        </div>
                    </div>
                })
                : <div className="product-head">
                    <div className="product-img">
                        <img src="\assets\images\no-product.png" alt="img"></img>
                    </div>
                    <h6> No Request For Quote (RFQ) in your list </h6>
                </div>}
            {/* {
                offerDataListRfq && offerDataListRfq.length < totalOffers ? <div className="plp-loadMore-div">
                    <button className="shop-now" onClick={handleLoadMore}>
                        {moreLoading ? <ButtonLoader content={"Loading"} /> : "Load more"}
                    </button>
                </div>
                    :
                    null
            } */}
        </div>
    )
}

export default RfqProduct;