import React from "react";
import { isEmpty } from "lodash";
import { ButtonLoader } from "../../../../utils/constants";
import MyListingCard from "./myListingCard";
import SearchFilter from "./searhFilter";
import Toast from "light-toast";

const ListingProduct = (props) => {
  const [buttonLoader, setButtonLoader] = React.useState(false);
  const [getDefaultValue, setGetDefaultValue] = React.useState("");

  const loadMore = () => {
    setButtonLoader(true);
    props.loadMore({
      page: props.myProduct.page + 1,
      type: props.type,
      searchValue: getDefaultValue
    });
    setTimeout(() => setButtonLoader(false), 700);
  };


  const loadMoreSearched = (searchedValue) => {
    // Toast.loading("Please Wait...");
    setButtonLoader(true);
    props.loadMore({
      page: 1,
      type: props.type,
      searchValue: searchedValue
    });
    // setTimeout(() => Toast.hide(), 800);
  };

  const loadSearchedData = (searchedValue) => {
    setGetDefaultValue(searchedValue);
    loadMoreSearched(searchedValue);
  }

  // React.useEffect(() => {

  //   if (!props.myProduct || props.myProduct.product.length === 0) {

  //     props.loadMore({
  //       page: 1,
  //       type: props.type,
  //     });
  //   }
  // }, [props.type, props.myProduct]);

  React.useEffect(() => {
    setGetDefaultValue("");

    props.loadMore({
      page: 1,
      status: props.type,
    });
  }, [props.type]);

  return (
    <>

      <div className="plp-product-screen my-listing-tab">

        <SearchFilter
          loadSearchedData={loadSearchedData}
          getDefaultValue={getDefaultValue}
        />
        {!isEmpty(props.myProduct) &&
          props.myProduct.product.length > 0 &&
          <div className="listing-content my-listing-wrapper new-my-list-wrap">
            <div className="listing-head">
              <div className="image-title my-listing-title"></div>
              <div className="price-title my-listing-title">Name/Price</div>
              <div className="qty-title my-listing-title">QTY</div>
              <div className="qty-title my-listing-title views-head">views</div>
              <div className="qty-title my-listing-title views-head">Size</div>
              {/* <div className="like-title my-listing-title">Likes</div> */}
              {/* <div className="view-title my-listing-title">Views</div> */}
              <div className="update-title my-listing-title">Update on</div>
              <div className="nav-title my-listing-title">Action</div>
            </div>

            <div className="listing-values listing-values-new-wrp">
              {props.myProduct.product.map((data, i) => {
                return (
                  <MyListingCard {...data}  {...props} openLikerPage={props.openLikerPage} />
                )
              }
              )}
            </div>
          </div>
        }

        {!isEmpty(props.myProduct) && props.myProduct.product.length === 0 && (
          <div className="product-head">
            <div className="product-img">
              <img src="\assets\images\no-product.png" alt="img"></img>
            </div>
            <h6>No Product Found</h6>
          </div>
        )}

        {!isEmpty(props.myProduct) &&
          props.myProduct.product.length < props.myProduct.productCount ? (
          <div className="plp-loadMore-div">
            <button className="shop-now" onClick={loadMore}>
              {buttonLoader ? <ButtonLoader content={"Loading"} /> : "Load more"}
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default ListingProduct;
