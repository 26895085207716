import React, { useState } from 'react'
import { axiosInstance } from '../../utils/Service'
import { useSelector } from 'react-redux';

const CounterRfq = ({ setLoader, setOfferPopup, productsDetail, sellerData }) => {
    const userData = useSelector(
        (store) => store.userProfile.userProfileBanner.userdata
      );

    const [qty, setQty] = useState({ value: "" })
    const [price, setPrice] = useState({ value: "" })
    const [commentBox, setCommentBox] = useState("")

    const [qtyError, setQtyError] = useState(false)
    const [qtyMoqError, setQtyMoqError] = useState(false)
    const [priceError, setPriceError] = useState(false)




    const handleQty = (event) => {
        let value = event.target.value
        value = value.replaceAll(' ', '')
        setQty(qty => value.length <= 10 && !isNaN(Number(value)) && { value } || qty)
        setQtyError(false);
        setQtyMoqError(false)
    }

    const handlePrice = (event) => {
        let value = event.target.value
        value = value.replaceAll(' ', '')
        setPrice(price => value.length <= 10 && !isNaN(Number(value)) && { value } || price)
        setPriceError(false)
    }

    console.log("productsDetail",productsDetail)
    const handleCounter = () => {
        if (qty.value.length === 0) {
            setQtyError(true)
            return
        }
        else if (price.value.length === 0) {
            setPriceError(true)
            return
        }
        setLoader(true)
       
        let token = localStorage.getItem("token");
        axiosInstance.post(`/requestForQuote/seller_counter_offer`, 
            {
                "requestId": productsDetail.data._id,
                "seller_request_id": sellerData.seller_request_id,
                "sellerId": sellerData.sellerId,
                "buyerId": productsDetail.data.buyerId,
                "quantity": qty.value,
                "price": price.value,
                comments:commentBox
        
        }, {
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            },
        }).then((res) => {
            setLoader(false)
            window.location.reload()
        }).catch(() => {
            setLoader(false)
        });
    }

    return (
        <div className='make-offer-popup-new'>
            <div className='offer-popup-content' style={{height:"52%"}}>
                <div className='offer-popup-content-header'>
                    <h3>Counter Offer ({sellerData.companyName})</h3>
                    <button
                        className=""
                        type="button"
                        onClick={() => setOfferPopup(false)}
                    >
                        <i className="fal fa-times-circle"></i>
                    </button>
                </div>
                <div className="slct-optns select-wrapper">
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Quantity (mt){" "}
                            <span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap">
                            <input
                                className={"input-url"}
                                type="text"
                                placeholder="Enter quantity"
                                value={qty.value}
                                onChange={handleQty}
                            />
                        </div>
                        {qtyError ? <p className='error'>Please enter quantity</p> : null}
                        {qtyMoqError ? <p className='error'>You cannot enter quantity less than minimum order quantity</p> : null}
                    </div>
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Price (INR/mt){" "}
                            <span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap">
                            <input
                                className={"input-url"}
                                type="text"
                                placeholder="Enter price"
                                value={price.value}
                                onChange={handlePrice}
                            />
                        </div>
                        {priceError ? <p className='error'>Please enter price</p> : null}
                    </div>
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Comment Box
                        </label>
                        <div className="form-input">
                            <textarea value={commentBox} placeholder='Enter comment' onChange={(e) => { setCommentBox(e.target.value) }} rows={3}></textarea>
                        </div>
                    </div>
                    <div className="navigation-back" style={{ flexDirection: "column" }}>
                        <button className="next-btn" onClick={handleCounter}>Counter Offer</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CounterRfq